import React from "react";
import ButtonComponent, {ButtonType} from "../../UI/Buttons/ButtonComponent";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactTyped } from "react-typed";

interface BannerProps {
}

export const Banner: React.FC<BannerProps> = () => {

    const getTyped = () => {
        return (<ReactTyped strings={["Fintech Startups"]} typeSpeed={150} backSpeed={100} loop></ReactTyped>)
    }
    // @ts-ignore
    return (
        <div className="flex flex-col w-full justify-center items-start lg:pr-10 lg:pl-10 pl-4 pr-4 gap-6 mb-[150px]">

            <div className="flex flex-col justify-between w-full">
                <div className="flex w-full mb-[40px]">
                    <h1 className="text-[42px] md:text-[100px] font-medium text-left w-full max-w-[840px]">Hire Pre-vetted Developers for <br />
                        <span className="inline-flex text-violet-700">
                        {getTyped()}
                    </span></h1>
                </div>

                {/*<div className="flex text-[28px]">Avoid the hassle of:</div>

                <div className="flex grid grid-cols-1 md:grid-cols-4 gap-0 mb-6">
                    <div className="flex flex-col p-4 bg-[#F3F2FF] h-[238px]">
                        <div className="flex flex-col">
                            <img src="/coins-image.svg"
                                 className="max-h-[71px] mr-auto pb-4"
                                 alt="Coins Image"/>
                            <div className="flex text-[18px]">Reduce high recruitment costs</div>
                        </div>
                        <div className="h-[1px] bg-violet-400 my-2"/>
                        <div className="flex flex-col text-[14px]">
                            We eliminate the need for expensive recruitment agencies by providing a curated pool of
                            developers at a fraction of the cost.
                        </div>

                    </div>
                    <div className="flex flex-col p-4 bg-[#EBE7FF] h-[238px]">
                        <div className="flex flex-col">
                            <img src="/rupor-image.svg"
                                 className="max-h-[71px] mr-auto pb-4"
                                 alt="Rupor Image"/>
                            <div className="flex text-[18px]">Streamline the hiring process</div>
                        </div>

                        <div className="h-[1px] bg-violet-400 my-2"/>

                        <div className="flex flex-col text-[14px]">
                            No more spending months looking for the right talent. Our platform enables you to find
                            pre-vetted developers within minutes, ensuring speed and quality.
                        </div>
                    </div>
                    <div className="flex flex-col p-4 bg-[#D6CEFF] h-[238px]">
                        <div className="flex flex-col">
                            <img src="/profile-image.svg"
                                 className="max-h-[71px] mr-auto pb-4"
                                 alt="Profile Image"/>
                            <div className="flex text-[18px]">Ensure consistent candidate quality</div>
                        </div>

                        <div className="h-[1px] bg-violet-400 my-2"/>

                        <div className="flex flex-col text-[14px]">
                            With our rigorous AI-powered vetting process, we ensure that only top-performing candidates
                            with proven skills make it through, ensuring consistent high-quality hires.
                        </div>
                    </div>
                    <div className="flex flex-col p-4 bg-[#C9BFFF]  h-[238px]">
                        <div className="flex flex-col text-left">
                            <img src="/search-image.svg"
                                 className="max-h-[71px] mr-auto pb-4"
                                 alt="Search Image"/>

                            <div className="flex text-[18px]">Increase transparency in recruitment</div>
                        </div>

                        <div className="h-[1px] bg-violet-500 my-2"/>

                        <div className="flex flex-col text-[14px]">
                            Our transparent developer profiles, complete with skill assessments and culture fit
                            evaluations, give you full visibility into the candidates you hire, reducing uncertainty.
                        </div>
                    </div>
                </div>*/}

                <div className="flex flex-col md:flex-row justify-between">

                    <div className="text-[24px] text-[#131314]">Access top developers tailored for fintech startups
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="36" viewBox="0 0 39 36" fill="none"
                            className="inline-flex">
                            <g clip-path="url(#clip0_1222_4337)">
                                <path d="M27.5061 17.9998H29.3185L28.4656 15.7998L27.5061 17.9998Z" fill="#EA3E2B"/>
                                <path
                                    d="M11.0877 17.5H9.06207V19H10.7679C11.1943 19.1 11.6208 18.8 11.7274 18.4C11.834 18 11.5142 17.6 11.0877 17.5Z"
                                    fill="#EA3E2B"/>
                                <path
                                    d="M11.4076 15.7996C11.4076 15.3996 11.0877 15.0996 10.5546 15.0996H9.06207V16.3996H10.5546C11.0877 16.4996 11.4076 16.1996 11.4076 15.7996Z"
                                    fill="#EA3E2B"/>
                                <path
                                    d="M35.2888 9H3.30499C2.66531 9 2.23886 9.4 2.23886 10V24C2.23886 24.6 2.66531 25 3.30499 25H7.5695V29C7.5695 29.4 7.78272 29.8 8.20917 29.9C8.31578 30 8.4224 30 8.63562 30C8.95546 30 9.16869 29.9 9.38191 29.7L14.3927 25H35.2888C35.9285 25 36.3549 24.6 36.3549 24V10C36.3549 9.4 35.8219 9 35.2888 9ZM11.0877 20.1H7.67611V14.1H10.8745C11.834 14 12.6869 14.6 12.7935 15.5V15.6C12.7935 16.2 12.4737 16.7 11.9406 16.9C12.5803 17.1 13.1134 17.7 13.1134 18.4C13.0067 19.4 12.1538 20.1 11.0877 20.1ZM19.0837 15.2H15.5654V16.4H18.7638V17.6H15.5654V18.9H19.0837V20.1H14.1795V14.1H19.0837V15.2ZM23.1349 20.1H21.749V15.3H19.7233V14.1H25.054V15.3H23.1349V20.1ZM30.278 20.1L29.8515 19.1H26.973L26.5466 20.1H25.054L27.6127 14.1H29.1053L31.7706 20.1H30.278Z"
                                    fill="#EA3E2B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1222_4337">
                                    <rect width="38.3806" height="36" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <ButtonComponent
                        isDisabled={false}
                        isLocal={true}
                        isLink={true}
                        type={ButtonType.PRIMARY}
                        text="Get started"
                        link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
                    />
                </div>
                {/*<ButtonComponent*/}
                {/*    isDisabled={false}*/}
                {/*    isLocal={true}*/}
                {/*    isLink={true}*/}
                {/*    type={ButtonType.PRIMARY}*/}
                {/*    text="Let's scale Your team"*/}
                {/*    link="/howWeWork"*/}
                {/*/>*/}
            </div>
        </div>
    );
};
