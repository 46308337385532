import React, { useEffect, useState } from 'react';
import useContentful from "../../API/Hooks/hooks";
import styles from './Blog.module.scss';
import ButtonComponent, {ButtonType} from "../../UI/Buttons/ButtonComponent";

export type BlogProps = {}

export default function BlogPage({}: BlogProps) {
    const [entries, setEntries] = useState([]);
    const { getEntryFromContentful } = useContentful();
    useEffect(() => {
        getEntryFromContentful("blogPost").then((response) => {
            const items = response!.map((item) => item?.fields);
            setEntries(items as any);
        });
    }, []);

    return (
        <div className="flex flex-col container p-6">
            <h1 className="flex text-[80px] text-[#131314] item-start self-start">Blog</h1>

            <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
                {entries
                    .reverse()
                    .map((entry: any, index) => (
                        <div className={`${styles.blogPreview} p-[1rem] flex flex-col border bg-[#F3F2FF] ${!index ? 'lg:col-span-2' : ''}`}>
                            <img src={entry.image.fields.file.url}
                                 className="mb-6"
                                 alt={entry.image.fields.title}/>
                            <div className="text-[28px] font-medium text-[#131314]">{entry.title}</div>
                            <div className="mt-auto flex w-full justify-between items-center">
                                <p className="text-[18px] text-[#505050]">Cristian Ciobanu</p>

                                <ButtonComponent
                                    link={`/blog-post/${entry.slug}`}
                                    isDisabled={false}
                                    isLocal={true}
                                    isLink={true}
                                    linkTo={`/blog-post/${entry.slug}`}
                                    type={ButtonType.ARROW_MOBILE_LINK}
                                />
                            </div>

                        </div>
                    ))
                }

            </div>
        </div>
    );
}
