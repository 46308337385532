import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {ReviewsList} from "../Widgets/company/ReviewsList";
import Skeleton from "react-loading-skeleton";
import ButtonComponent, {ButtonType} from "../UI/Buttons/ButtonComponent";
import gsap from 'gsap'; // <-- import GSAP
import ScrollTrigger from 'gsap/ScrollTrigger';
import {useGSAP} from '@gsap/react';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface StartupsPageProps {
}

export type CardDetails = {
    indexKey: number;
    'data-animation-item': string;
    title: string;
    description: string;
    classNames?: string;
    footer?: string
}

export class CardItem extends React.Component<CardDetails> {

    render() {
        return (
            <div
                data-animation-item={this.props["data-animation-item"]}
                className={`card-item flex flex-col justify-between content-between mb-3.5 w-[555px] max-w-[100%] min-h-[300px] p-[1rem] ${this.props.classNames} z-${this.props.indexKey}`}>
                <div className="flex font-bold text-4xl">{this.props.title}</div>
                <div className="flex flex-col">
                    <div className="divide-black h-[1px] bg-black align-bottom mt-auto"/>
                    <div className="flex text-xl mt-auto">{this.props.description}</div>
                </div>
            </div>
        );
    }
}

export const StartupsPage: React.FC<StartupsPageProps> = ({}) => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useGSAP(
        () => {
            // gsap code here...
            const cardItems = gsap.utils.toArray(".card-item");

            //  initialization
            const createScrollTrigger = (item: any, index: number) => {
                const st = ScrollTrigger.create({
                    trigger: item,
                    start: "top 100+=" + item.getAttribute("data-animation-item"),
                    endTrigger: ".container2",
                    end: "top 400",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    markers: false
                });

                return gsap.timeline({st});
            };

            const ct = ScrollTrigger.create({
                trigger: '.container-title',
                start: "top 100",
                endTrigger: ".container2",
                end: "top 400",
                pin: true,
                pinSpacing: false,
                scrub: true,
                markers: false
            });

            // start
            cardItems.forEach((item: any, index) => {
                let tll = createScrollTrigger(item, index);

                tll.to(item, {
                    transformOrigin: "center center"
                });
            });
        },
    );

    return (
        <div className="w-full flex flex-col justify-center items-center gap-6 lg:pr-10 lg:pl-10 pl-4 pr-4">

            <div className="w-full h-full flex flex-col justify-around gap-6 lg:gap-8 mb-[100px]">
                <div className="flex flex-col lg:flex-row justify-between justify-items-stretch">

                    <h1 className="w-full text-left text-[40px] md:text-[80px] lg:text-[80px] min:w-3/5">
                        <span className="text-[#6A2BE9]">Special offer</span> for seed-<br/>stage startups
                        unlock top
                        talent at
                        <span  className="text-[#6A2BE9]"> 75% off</span>
                    </h1>

                    <div className="flex w-full h-full justify-items-stretch w-2/5" style={{maxWidth: '390px'}}>
                        <iframe
                            className="w-full h-[440px] ml-auto"
                            src="https://lottie.host/embed/c0a2a160-f04f-4824-8a83-9a1c4942d42e/DLIQH3ct2z.json"></iframe>
                    </div>

                </div>

                <div className="flex flex-col lg:flex-row align-bottom w-full justify-between text-left">

                    <p className="w-full text-left text-base justify-self-start lg:text-2xl max-w-[700px] md:w-1/2 lg:w-3/5">
                        We know that finding the right technical talent is critical to your growth, especially in the
                        early stages of building a business. That’s why we’ve created a special offer exclusively for
                        ambitious seed-stage startups.
                    </p>

                    <ButtonComponent
                        isDisabled={false}
                        classNames="flex mt-auto lg:w-[330px]"
                        isLocal={false}
                        isLink={true}
                        type={ButtonType.PRIMARY}
                        text="Start scaling with DevSupply"
                        link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
                    />
                </div>

            </div>

            {/* Section: Eligibility criteria */}
            <div className="flex card-holder-pinner w-full">
                <div className="flex flex-col lg:flex-row justify-between w-full"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 text-violet-700 flex-col container-title">Eligibility
                        criteria:
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2 items-end">

                        <CardItem
                            title="Seed Stage"
                            indexKey={0}
                            data-animation-item="0px"
                            description="Your startup is actively growing and seeking to expand its technical team."
                            classNames="box-1 bg-[#BCAFFF]"/>

                        <CardItem
                            indexKey={1}
                            title="Revenue"
                            data-animation-item="70px"
                            description="Your annual revenue does not exceed €500,000."
                            classNames="box-2 bg-[#D8D3FF]"/>

                        <CardItem
                            indexKey={2}
                            title="Netherlands-Based"
                            data-animation-item="140px"
                            description="Your startup is registered in the Netherlands."
                            classNames="box-3 bg-[#EBE7FF]"/>

                        <CardItem
                            indexKey={3}
                            title="Growth Potential"
                            data-animation-item="210px"
                            description="You have a strong vision and demonstrated potential to grow significantly within the next year."
                            classNames="bg-[#F3F2FF] box-4"/>
                    </div>
                </div>
            </div>

            {/* Section: Why we choose */}
            <div className="flex flex-col container2 justify-items-start w-full pt-[220px]">
                <h1 className="w-full text-left text-[40px] md:text-[80px] lg:text-[80px] why-us text-violet-700 mb-8">Why choose this
                    offer?</h1>

                <div className="flex text-[32px] text-[#131314] w-full lg:w-1/2 mb-7">
                    This exclusive offer gives high-potential startups the chance to access pre-vetted developers at a
                    fraction of the cost, helping you focus on scaling your business with the right technical talent on
                    board.
                </div>

                <div className="text-2xl text-violet-800 w-full lg:w-1/2">Don’t let high recruitment fees slow down your growth—this is your chance to build the team that
                    will take you to the next level.
                </div>
            </div>

            {/* Section: Advantage */}
            <div className="flex flex-col lg:flex-row py-40 lg:py-0 lg:h-[70vh] justify-between items-center w-full">

                <div className="flex w-full lg:w-3/4 text-left text-[40px] md:text-[80px] lg:text-[80px] mb-6 lg:mb-0 text-violet-700">Take Advantage of the <br /> 75% Discount Today!</div>

                <ButtonComponent
                    isDisabled={false}
                    classNames="flex lg:w-[330px]"
                    isLocal={false}
                    isLink={true}
                    type={ButtonType.PRIMARY}
                    text="Start scaling with DevSupply"
                    link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
                />
            </div>
        </div>
    );
};
