import React from 'react';
import ButtonComponent, {ButtonType} from "../../UI/Buttons/ButtonComponent";

export type NotfoundpageProps = {}

export default function NotFoundPage({}: NotfoundpageProps) {
    return (
        <div className="NotFoundPage flex flex-col items-center">
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="900"
                 height="255"
                 className="mb-[80px]"
                 viewBox="0 0 900 255"
                 fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M510.719 25.4381C514.828 41.3341 516.95 60.6017 516.95 83.1331C516.95 156.443 494.195 195.227 453.424 195.227C413.128 195.227 390.372 156.443 390.372 83.1331C390.372 65.2481 391.709 49.4196 394.309 35.7014C364.521 36.3336 338.538 35.4848 315.88 33.3859C313.101 48.6962 311.675 65.306 311.675 83.1331C311.675 190.97 364.298 254.348 453.424 254.348C543.024 254.348 595.647 190.97 595.647 83.1331C595.647 51.6156 591.191 23.9029 582.665 0.449335C561.459 11.4722 537.799 19.8492 510.719 25.4381ZM623.614 123.335L709.344 0.296875C726.395 3.78068 745.118 7.44405 765.958 11.2014C763.933 14.4144 761.693 17.8495 759.199 21.6471L712.266 90.7006C702.784 104.417 697.095 111.984 690.458 119.079H778.162V24.4849C778.162 20.276 778.162 16.645 778.177 13.3849C783.506 14.3885 789.208 15.7124 795.165 17.0958C812.466 21.1134 831.925 25.6321 850.696 24.2589V119.079H900V174.889H850.696V247.253H775.318V174.889H623.614V123.335ZM0 123.335L79.4409 9.32357C92.8377 19.2387 108.724 27.1976 128.374 32.2585L88.6522 90.7006C79.1707 104.417 73.4818 111.984 66.8447 119.079H154.549V36.9595C175.018 39.2108 198.895 38.8498 227.082 35.2084V119.079H276.386V174.889H227.082V247.253H151.704V174.889H0V123.335Z"
                      fill="#6A2BE9"/>
            </svg>

            <p className="text-[32px] text-[#131314]">Page not found</p>

            <ButtonComponent
                isDisabled={false}
                classNames="flex mt-auto lg:w-[330px]"
                isLocal={true}
                isLink={true}
                type={ButtonType.PRIMARY}
                text="Back Home"
                link="/"
            />
        </div>
    );
}
