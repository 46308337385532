import React, {FormEvent, useEffect, useState} from 'react';
import {useWindowSize} from 'react-use'
import Confetti from 'react-confetti'

import styles from './EmailerBanner.module.scss';
import axios from "axios";

export type EmailerbannerProps = {}

export default function EmailerBanner({}: EmailerbannerProps) {
    const {width, height} = useWindowSize()
    const [isSubmited, setIsSubmited] = useState(false)
    const [message, setMessage] = useState<string|null>(null)
    const [formData, setFormData] = useState({ email: "" });
    const [isVisible, setIsVisible] = useState(true);
    const DOMAIN = process.env.NODE_ENV === 'production' ? 'https://artificial-interviewer.vercel.app' : 'http://localhost:3000';
    const API_URL = `${DOMAIN}/api/subscribe`;

    const handleChange = (e: any) => {
        setFormData({ 'email': e?.target?.value });
    };

    const isValidEmail = (email: string): any|null => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // prevent default behaviour of browser to reload the page

        if ( isValidEmail(formData.email) ) {
            try {
                await axios.post(API_URL, formData);
                setFormData({ email: '' });
                setIsVisible(true);
                setIsSubmited(true);
                setMessage(null);
                handleClose();
            } catch (error: any) {
                setMessage(error?.response?.data?.error);
            }
        } else {
            setMessage("Please use a valid email");
        }
    };

    useEffect(() => {
        const hideTime = Number(localStorage.getItem("bannerHiddenUntil"));

        if (hideTime && Date.now() < hideTime) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        const nextShowTime = Date.now() + 600000; // 10 минута (60 000 мс)
        localStorage.setItem("bannerHiddenUntil", nextShowTime.toString());

        setTimeout(() => {
            setIsVisible(true);
        }, 600000);

    };

    return (<div
            className="EmailerBanner flex flex-col rounded transition-all duration-300 fixed right-2.5 bottom-2.5 w-[524px] max-w-[95vw] bg-white p-[32px] items-center z-50"
            style={{
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 6px 2px rgba(0, 0, 0, 0.10)',
                bottom: (isVisible ? '10px' : '-100%')
            }}>

            <svg xmlns="http://www.w3.org/2000/svg"
                 className="flex mb-[25px]"
                 width="256"
                 height="18"
                 viewBox="0 0 256 18"
                 fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M94.4233 0.677784C94.0286 0.747858 93.5344 0.944412 93.2024 1.1633C92.2053 1.82056 92.0236 2.48248 92.0073 2.83934C91.9909 3.19621 92.0073 6.93977 92.0073 6.93977C92.0073 6.93977 92.0073 10.6124 92.0073 11.0402C92.0073 11.468 94.0971 13.5323 95.1544 14.6429L97.9449 17.5742L97.9601 12.307C97.9712 8.48013 97.9955 6.99965 98.0491 6.89297C98.0897 6.81223 98.2092 6.71468 98.3148 6.67628C98.5787 6.5802 103.651 6.58107 103.893 6.67728C103.991 6.71622 104.093 6.78369 104.12 6.82729C104.146 6.87083 104.168 7.92828 104.169 9.17727C104.17 11.0339 104.153 11.4717 104.079 11.5775C103.993 11.7015 103.866 11.7083 101.065 11.7403L98.1413 11.7736V14.7631C98.1413 16.8634 98.1413 17.611 98.1413 17.611H103.755H104.985H106.224C106.224 17.611 107.895 17.6534 108.671 17.5742C109.447 17.495 110.126 16.5829 110.302 15.5884C110.344 15.3522 110.361 13.7648 110.349 11.2284C110.349 11.2284 110.36 7.80233 110.331 7.2398C110.302 6.67728 107.949 4.4474 107.349 3.81705C106.31 2.72534 104.393 0.699631 103.946 0.639113C103.5 0.578595 99.3495 0.629179 99.3495 0.629179C96.8212 0.623712 94.6044 0.645581 94.4233 0.677784Z"
                      fill="#FF3E3E"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M94.4233 0.0684389C94.0286 0.138543 93.5344 0.335183 93.2024 0.554168C92.2053 1.21171 92.0236 1.87392 92.0073 2.23094C91.9909 2.58796 92.0073 6.33316 92.0073 6.33316C92.0073 6.33316 92.0073 10.0074 92.0073 10.4354C92.0073 10.8634 94.0971 12.9286 95.1544 14.0397L97.9449 16.9723L97.9601 11.7027C97.9712 7.8742 97.9955 6.39306 98.0491 6.28634C98.0897 6.20556 98.2092 6.10798 98.3148 6.06956C98.5787 5.97344 103.651 5.9743 103.893 6.07056C103.991 6.10951 104.093 6.17701 104.12 6.22064C104.146 6.26419 104.168 7.3221 104.169 8.57164C104.17 10.429 104.153 10.8671 104.079 10.9729C103.993 11.0969 103.866 11.1038 101.065 11.1358L98.1413 11.1691V14.16C98.1413 16.2611 98.1413 17.009 98.1413 17.009H103.755L106.213 16.9906C106.213 16.9906 107.895 17.0515 108.671 16.9723C109.447 16.893 110.126 15.9806 110.302 14.9856C110.344 14.7493 110.361 13.1611 110.349 10.6236C110.349 10.6236 110.36 7.1961 110.331 6.63333C110.302 6.07056 107.949 3.8397 107.349 3.20907C106.31 2.11689 104.393 0.0902957 103.946 0.0297512C103.5 -0.0307933 99.3495 0.0198126 99.3495 0.0198126C96.8212 0.0143429 94.6044 0.0362214 94.4233 0.0684389Z"
                      fill="#5E1AFF" fill-opacity="0.95"/>
                <path
                    d="M116.088 14.9617V6.45526H118.734C119.287 6.45526 119.805 6.56666 120.288 6.78944C120.772 7.00818 121.197 7.31401 121.563 7.70693C121.929 8.09579 122.214 8.54745 122.42 9.06189C122.629 9.57227 122.734 10.1211 122.734 10.7085C122.734 11.2958 122.629 11.8467 122.42 12.3612C122.214 12.8716 121.929 13.3232 121.563 13.7161C121.197 14.105 120.772 14.4108 120.288 14.6336C119.805 14.8524 119.287 14.9617 118.734 14.9617H116.088ZM117.277 13.6979H118.734C119.123 13.6979 119.485 13.6209 119.82 13.467C120.159 13.3131 120.458 13.0984 120.717 12.823C120.98 12.5475 121.184 12.2295 121.328 11.869C121.473 11.5085 121.546 11.1217 121.546 10.7085C121.546 10.2953 121.473 9.90848 121.328 9.54797C121.184 9.18746 120.98 8.86948 120.717 8.59403C120.458 8.31858 120.159 8.1039 119.82 7.94997C119.485 7.79604 119.123 7.71908 118.734 7.71908H117.277V13.6979Z"
                    fill="#5E1AFF"/>
                <path
                    d="M126.136 15.1197C125.611 15.1197 125.131 14.9759 124.696 14.6883C124.266 14.4007 123.921 14.0159 123.662 13.5339C123.407 13.0478 123.279 12.5111 123.279 11.9237C123.279 11.4781 123.353 11.0629 123.502 10.6781C123.651 10.2892 123.854 9.94899 124.113 9.65734C124.376 9.36164 124.681 9.13075 125.028 8.96467C125.374 8.79859 125.744 8.71555 126.136 8.71555C126.574 8.71555 126.976 8.81277 127.342 9.0072C127.708 9.19758 128.02 9.46291 128.279 9.80316C128.538 10.1394 128.729 10.5282 128.851 10.9698C128.973 11.4072 129.009 11.8731 128.959 12.3673H124.553C124.603 12.6468 124.698 12.8999 124.839 13.1268C124.984 13.3496 125.167 13.5258 125.388 13.6554C125.613 13.785 125.862 13.8518 126.136 13.8559C126.426 13.8559 126.689 13.7789 126.925 13.625C127.165 13.4711 127.361 13.2584 127.514 12.987L128.719 13.2847C128.491 13.8235 128.146 14.265 127.685 14.6093C127.224 14.9496 126.708 15.1197 126.136 15.1197ZM124.513 11.4012H127.759C127.721 11.1095 127.624 10.8462 127.468 10.6113C127.315 10.3723 127.123 10.1839 126.891 10.0462C126.662 9.90443 126.411 9.83354 126.136 9.83354C125.866 9.83354 125.614 9.90241 125.382 10.0401C125.153 10.1779 124.963 10.3662 124.811 10.6052C124.662 10.8401 124.563 11.1055 124.513 11.4012Z"
                    fill="#5E1AFF"/>
                <path
                    d="M132.906 14.9617H131.381L129.295 8.87353H130.564L132.141 13.5035L133.724 8.87353H134.986L132.906 14.9617Z"
                    fill="#5E1AFF"/>
                <path
                    d="M138.862 15.1197C138.424 15.1197 138.003 15.0468 137.599 14.901C137.195 14.7551 136.843 14.5465 136.542 14.2751C136.241 13.9997 136.026 13.6736 135.896 13.2969L137.011 12.8533C137.075 13.0478 137.199 13.222 137.382 13.3759C137.569 13.5258 137.79 13.6432 138.045 13.7283C138.304 13.8134 138.576 13.8559 138.862 13.8559C139.167 13.8559 139.453 13.8032 139.719 13.6979C139.99 13.5926 140.207 13.4468 140.371 13.2604C140.538 13.0741 140.622 12.8554 140.622 12.6042C140.622 12.345 140.535 12.1323 140.359 11.9662C140.188 11.8002 139.967 11.6685 139.697 11.5713C139.43 11.4741 139.152 11.4012 138.862 11.3526C138.302 11.2553 137.799 11.1136 137.354 10.9272C136.912 10.7409 136.561 10.4796 136.302 10.1434C136.047 9.80721 135.919 9.36974 135.919 8.831C135.919 8.32466 136.055 7.88313 136.325 7.50642C136.599 7.1297 136.959 6.83805 137.405 6.63147C137.855 6.42083 138.34 6.31551 138.862 6.31551C139.293 6.31551 139.708 6.38842 140.108 6.53425C140.512 6.67602 140.866 6.88464 141.171 7.16008C141.479 7.43148 141.7 7.76161 141.834 8.15048L140.708 8.58188C140.643 8.38339 140.519 8.21124 140.337 8.06541C140.154 7.91554 139.933 7.79807 139.674 7.713C139.418 7.62794 139.148 7.58541 138.862 7.58541C138.557 7.58136 138.272 7.63401 138.005 7.74338C137.742 7.8487 137.527 7.99453 137.359 8.18086C137.192 8.36719 137.108 8.5839 137.108 8.831C137.108 9.1348 137.186 9.36569 137.342 9.52367C137.498 9.68164 137.708 9.79911 137.971 9.87608C138.237 9.94899 138.535 10.0158 138.862 10.0766C139.388 10.1657 139.874 10.3136 140.319 10.5201C140.769 10.7267 141.129 11.0022 141.399 11.3465C141.674 11.6867 141.811 12.106 141.811 12.6042C141.811 13.1065 141.674 13.548 141.399 13.9288C141.129 14.3055 140.769 14.5992 140.319 14.8098C139.874 15.0164 139.388 15.1197 138.862 15.1197Z"
                    fill="#5E1AFF"/>
                <path
                    d="M142.758 12.6832V8.88568H143.946V12.3733C143.946 12.6447 144.009 12.8918 144.135 13.1146C144.261 13.3374 144.428 13.5156 144.638 13.6493C144.847 13.7789 145.078 13.8437 145.329 13.8437C145.584 13.8437 145.815 13.7789 146.021 13.6493C146.23 13.5156 146.396 13.3374 146.518 13.1146C146.644 12.8918 146.706 12.6447 146.706 12.3733V8.88568H147.895L147.901 14.9617H146.712L146.706 14.3055C146.512 14.5567 146.27 14.7551 145.981 14.901C145.691 15.0468 145.381 15.1197 145.049 15.1197C144.626 15.1197 144.242 15.0103 143.895 14.7916C143.548 14.5729 143.272 14.2792 143.066 13.9106C142.861 13.542 142.758 13.1328 142.758 12.6832Z"
                    fill="#5E1AFF"/>
                <path
                    d="M150.023 17.9998H148.835V8.88568H150.023V9.77886C150.214 9.46696 150.458 9.21379 150.755 9.01935C151.052 8.82492 151.402 8.7277 151.806 8.7277C152.221 8.7277 152.61 8.81074 152.972 8.97682C153.338 9.1429 153.658 9.37379 153.932 9.66949C154.206 9.96114 154.421 10.2994 154.578 10.6842C154.734 11.069 154.812 11.4822 154.812 11.9237C154.812 12.3652 154.734 12.7804 154.578 13.1693C154.421 13.5582 154.206 13.9004 153.932 14.1961C153.658 14.4878 153.338 14.7167 152.972 14.8827C152.61 15.0488 152.221 15.1319 151.806 15.1319C151.402 15.1319 151.052 15.0346 150.755 14.8402C150.458 14.6417 150.214 14.3886 150.023 14.0807V17.9998ZM151.795 9.94899C151.463 9.94899 151.172 10.0381 150.92 10.2163C150.669 10.3946 150.473 10.6336 150.332 10.9333C150.191 11.229 150.12 11.5591 150.12 11.9237C150.12 12.2883 150.191 12.6204 150.332 12.9202C150.473 13.2199 150.669 13.4589 150.92 13.6372C151.172 13.8154 151.463 13.9045 151.795 13.9045C152.122 13.9045 152.423 13.8174 152.698 13.6432C152.972 13.465 153.191 13.226 153.355 12.9263C153.519 12.6265 153.6 12.2923 153.6 11.9237C153.6 11.5632 153.519 11.2331 153.355 10.9333C153.195 10.6336 152.978 10.3946 152.703 10.2163C152.429 10.0381 152.126 9.94899 151.795 9.94899Z"
                    fill="#5E1AFF"/>
                <path
                    d="M156.932 17.9998H155.743V8.88568H156.932V9.77886C157.122 9.46696 157.366 9.21379 157.663 9.01935C157.96 8.82492 158.311 8.7277 158.715 8.7277C159.13 8.7277 159.519 8.81074 159.881 8.97682C160.246 9.1429 160.566 9.37379 160.841 9.66949C161.115 9.96114 161.33 10.2994 161.486 10.6842C161.642 11.069 161.721 11.4822 161.721 11.9237C161.721 12.3652 161.642 12.7804 161.486 13.1693C161.33 13.5582 161.115 13.9004 160.841 14.1961C160.566 14.4878 160.246 14.7167 159.881 14.8827C159.519 15.0488 159.13 15.1319 158.715 15.1319C158.311 15.1319 157.96 15.0346 157.663 14.8402C157.366 14.6417 157.122 14.3886 156.932 14.0807V17.9998ZM158.703 9.94899C158.372 9.94899 158.08 10.0381 157.829 10.2163C157.578 10.3946 157.381 10.6336 157.24 10.9333C157.1 11.229 157.029 11.5591 157.029 11.9237C157.029 12.2883 157.1 12.6204 157.24 12.9202C157.381 13.2199 157.578 13.4589 157.829 13.6372C158.08 13.8154 158.372 13.9045 158.703 13.9045C159.031 13.9045 159.332 13.8174 159.606 13.6432C159.881 13.465 160.1 13.226 160.263 12.9263C160.427 12.6265 160.509 12.2923 160.509 11.9237C160.509 11.5632 160.427 11.2331 160.263 10.9333C160.103 10.6336 159.886 10.3946 159.612 10.2163C159.338 10.0381 159.035 9.94899 158.703 9.94899Z"
                    fill="#5E1AFF"/>
                <path d="M162.574 14.9617V5.84766H163.762V14.9617H162.574Z" fill="#5E1AFF"/>
                <path
                    d="M168.732 8.88568H170L166.869 17.9998H165.606L166.64 14.9617L164.589 8.88568H165.886L167.234 13.2301L168.732 8.88568Z"
                    fill="#5E1AFF"/>
            </svg>

            <svg width="32"
                 className="absolute right-3.5 top-3.5 cursor-pointer"
                 height="32"
                 onClick={handleClose}
                 viewBox="0 0 32 32"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4339_10282)">
                    <path
                        d="M16 14.1156L22.6 7.51562L24.4853 9.40096L17.8853 16.001L24.4853 22.601L22.6 24.4863L16 17.8863L9.39998 24.4863L7.51465 22.601L14.1146 16.001L7.51465 9.40096L9.39998 7.51562L16 14.1156Z"
                        fill="#1B064B"/>
                </g>
                <defs>
                    <clipPath id="clip0_4339_10282">
                        <rect width="32" height="32" rx="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>


            <div className="flex text-[24px] mb-[24px]">Devsupply AI: launching 2025! 🚀 🔍</div>

            <p>The ultimate platform for recruiting pre-vetted fintech developers in the Netherlands 🇳🇱<br/>
                Coming soon: AI-powered talent vetting, lightning-fast matching, and smart search. All
                crafted by
                fintech experts with startup DNA.</p>

            <img src={'/DevscoutEmailerImage.png'}
                 width={460}
                 height={358}
                 className="mb-[35px]"
                 alt="DevscoutEmailerImage"/>

            <p className="mb-[16px]">Be the first to experience how we're redefining recruitment!</p>

            <form className="flex rounded relative w-full"
                  noValidate={true}
                  onSubmit={(e) => handleSubmit(e)}>

                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    className="h-[60px] w-full border-1 rounded-[30px] p-[16px]"
                    style={{border: '1px solid #C9C9C9'}}
                    placeholder={'your@email.com'}
                    onChange={handleChange}/>

                <button
                    disabled={false}
                    type="submit"
                    style={{background: '#181818'}}
                    className='lg:w-52 md:w-56 w-full h-11 lg:h-12 flex justify-center items-center bg-white text-black border-2 hover:border-violet-700 disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed absolute right-2 top-1.5 bg-[#181818] text-white rounded-[30px] max-w-[145px]'
                >
                    Send
                </button>

                <div className="flex my-2 text-red-700 text-[14px] font-[400] absolute bottom-[-2rem] left-3">{message}</div>

            </form>


            <Confetti
                run={isSubmited}
                width={width}
                height={height}
            />

        </div>
    )
}
