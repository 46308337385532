import React, { useEffect, useState } from "react";
import { GrowWithUsInterface } from "../../Interfaces/GrowWithUs.interface";
import useContentful from "../../API/Hooks/hooks";
import ButtonComponent, { ButtonType } from "../../UI/Buttons/ButtonComponent";
interface GrowWithUsProps {}

export const GrowWithUs: React.FC<GrowWithUsProps> = ({}) => {
  const [entry, setEntry] = useState<GrowWithUsInterface>(
    {} as GrowWithUsInterface
  );
  const [parts, setParts] = useState<string[]>([]);
  const { getEntryFromContentful } = useContentful();

  useEffect(() => {
    getEntryFromContentful("growWithUs").then((entry) => {
      const item = entry![0].fields;
      setParts((item!.text! as string).split("|"));
      setEntry(item as any);
    });
  }, []);
  return (
    <div
      className="flex flex-col justify-between items-start w-full pt-6 gap-6 lg:pr-10 lg:pl-10 pl-4 pr-4 mt-4 md:mt-6 lg:mt-9"
      style={{
        backgroundColor: "#F3F2FF",
      }}
    >
      <div className=" text-4xl md:text-7xl lg:text-9xl w-full text-left">
        {entry.title}
      </div>
      <div className="w-full md:w-1/3">
        {parts.map((part, index) => {
          if (part.startsWith("<color>") && part.endsWith("</color>")) {
            return (
              <span
                key={index}
                className="text-base md:text-lg lg:text-2xl"
                style={{
                  color: `#6A2BE9`,
                }}
              >
                {part.substring(7, part.length - 8)}
              </span>
            );
          } else {
            return (
              <span className="text-base md:text-lg lg:text-2xl" key={index}>
                {part}
              </span>
            );
          }
        })}
      </div>
      <div className="flex flex-row justify-center md:justify-end items-center w-full pb-6">
        <ButtonComponent
          isDisabled={false}
          isLink={true}
          isLocal={false}
          link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
          type={ButtonType.PRIMARY}
          text="Join Us"
        />
      </div>
    </div>
  );
};
