import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as LogoIcon } from "../UI/Icons/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import ButtonComponent, { ButtonType } from "../UI/Buttons/ButtonComponent";
import { ReactComponent as BurgerIconOpen } from "../UI/Icons/BurgerIconOpen.svg";
import { ReactComponent as CloseIcon } from "../UI/Icons/CloseIcon.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const getLinkClass = (path: string) =>
    location.pathname === path ? "text-violet-700 underline" : "text-black hover:underline";

  return (
    <header className="flex items-center justify-between w-full pt-6 pb-6 lg:pr-10 lg:pl-10 pl-4 pr-4 backdrop-blur-md sticky top-0 mb-20 z-[55]">
      <nav className="hidden lg:flex space-x-4">
        <Link to="/aboutUs" className={getLinkClass("/aboutUs")}>
          About us
        </Link>
        <Link to="/ourServices" className={getLinkClass("/ourServices")}>
          Our services
        </Link>
        {/*<Link to="/ourWork" className={getLinkClass("/ourWork")}>*/}
        {/*  Our work*/}
        {/*</Link>*/}
        <Link to="/pricing" className={getLinkClass("/pricing")}>
          Pricing
        </Link>
        {/*<Link to="/howWeWork" className={getLinkClass("/howWeWork")}>
          How we work
        </Link>*/}
        <Link to="/forStartups" className={getLinkClass("/forStartups")}>
          For Startups
        </Link>
        <Link to="/Blog" className={getLinkClass("/Blog")}>
          Blog
        </Link>
      </nav>
      <Link to="/" className="flex items-center cursor-pointer">
        <LogoIcon />
      </Link>
      <div className="hidden lg:flex">

        <ButtonComponent
          isDisabled={false}
          text="For Companies"
          classNames="mr-[16px]"
          isLocal={false}
          isLink={true}
          type={ButtonType.SECONDARY}
          link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
        />

        <ButtonComponent
          isDisabled={false}
          text="For Developers"
          isLocal={false}
          isLink={true}
          type={ButtonType.SECONDARY}
          link="https://bj0gsq7q6qg.typeform.com/to/xkSEPJyW"
        />

      </div>
      <div className="flex lg:hidden">
        <button onClick={toggleMenu}>
          {isMenuOpen ? (
            <CloseIcon width={"44px"} height={"44px"} />
          ) : (
            <BurgerIconOpen width={"44px"} height={"44px"} />
          )}
        </button>
      </div>
      <div
        ref={menuRef}
        className={`${
          isMenuOpen ? "max-h-screen" : "max-h-0"
        } overflow-hidden transition-max-height duration-300 ease-in-out lg:hidden w-full absolute top-full left-0 bg-white z-40`}
      >
        <nav className="flex flex-col justify-center items-center text-[#131314] space-y-8 p-4">
          <Link to="/aboutUs" className={getLinkClass("/aboutUs")} onClick={toggleMenu}>
            About us
          </Link>
          <Link to="/ourServices" className={`${getLinkClass("/ourServices")}`} onClick={toggleMenu}>
            Our services
          </Link>
          <Link to="/pricing" className={`${getLinkClass("/pricing")}`} onClick={toggleMenu}>
            Pricing
          </Link>
          <Link to="/forStartups" className={getLinkClass("/forStartup")} onClick={toggleMenu}>
            For Startup
          </Link>
          <Link to="/Blog"
                className={getLinkClass("/Blog")}
                onClick={toggleMenu}
          >
            Blog
          </Link>

          <ButtonComponent
              isDisabled={false}
              text="For Companies"
              isLocal={false}
              isLink={true}
              type={ButtonType.SECONDARY}
              link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
          />

          <ButtonComponent
            isDisabled={false}
            isLink={true}
            classNames="mt-[20px]"
            text="For Developers"
            isLocal={false}
            type={ButtonType.SECONDARY}
            link="https://bj0gsq7q6qg.typeform.com/to/xkSEPJyW"
            onClick={toggleMenu}
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
