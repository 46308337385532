import React, {useEffect} from "react";
import ButtonComponent, {ButtonType} from "../UI/Buttons/ButtonComponent";
import {CardDetails, CardItem} from "./StartupsPage";
import gsap from 'gsap'; // <-- import GSAP
import ScrollTrigger from 'gsap/ScrollTrigger';
import {useGSAP} from '@gsap/react';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

interface OurServicesPageProps {
}

export class CardItem2 extends React.Component<CardDetails> {

    render() {
        return (
            <div
                data-animation-item={this.props["data-animation-item"]}
                className={`card-item-2 flex flex-col justify-between content-between mb-3.5 w-[555px] max-w-[100%] min-h-[300px] p-[1rem] ${this.props.classNames} z-${this.props.indexKey}`}>
                <div className="flex font-bold text-4xl">{this.props.title}</div>
                <div className="flex flex-col">
                    <div className="divide-black h-[1px] bg-black align-bottom mt-auto"/>
                    <div className="flex text-xl mt-auto">{this.props.description}</div>
                </div>
            </div>
        );
    }
}

export const OurServicesPage: React.FC<OurServicesPageProps> = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    useGSAP(
        () => {
            // gsap code here...
            const cardItems = gsap.utils.toArray(".card-item");
            const secondCardItems = gsap.utils.toArray(".card-item-2");

            //  initialization
            const createScrollTrigger = (item: any, index: number) => {
                const st = ScrollTrigger.create({
                    trigger: item,
                    start: "top 100+=" + item.getAttribute("data-animation-item"),
                    endTrigger: ".container2",
                    end: "top 400",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    markers: false
                });

                return gsap.timeline({st});
            };

            const ct = ScrollTrigger.create({
                trigger: '.container-title',
                start: "top 100",
                endTrigger: ".container2",
                end: "top 400",
                pin: true,
                pinSpacing: false,
                scrub: true,
                markers: false
            });

            //  initialization card item 2
            const createScrollTrigger2 = (item: any, index: number) => {
                const st2 = ScrollTrigger.create({
                    trigger: item,
                    start: "top 100+=" + item.getAttribute("data-animation-item"),
                    endTrigger: ".container3",
                    end: "top 400",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    markers: false
                });

                return gsap.timeline({st2});
            };

            const ct2 = ScrollTrigger.create({
                trigger: '.container-title2',
                start: "top 100",
                endTrigger: ".container3",
                end: "top 400",
                pin: true,
                pinSpacing: false,
                scrub: true,
                markers: false
            });

            // start secondCardItems animation
            secondCardItems.forEach((item: any, index) => {
                let tll = createScrollTrigger2(item, index);

                tll.to(item, {
                    transformOrigin: "center center"
                });
            });

            // start cardItems animation
            cardItems.forEach((item: any, index) => {
                let tll = createScrollTrigger(item, index);

                tll.to(item, {
                    transformOrigin: "center center"
                });
            });
        },
    );

    return (
        <div className="flex flex-col w-full h-auto justify-center items-center gap-6">
            <div className="w-full flex flex-col lg:flex-row justify-between mb-[120px] items-center px-4 lg:px-0">
                <h1 className="w-full text-left text-[40px] text-bold md:text-[80px] max-w-[900px] w-full lg:w-3/5">
                    Access high-quality developers tailored to <span className="text-violet-700">fintech</span>
                </h1>

                <div className="flex h-[260px] lg:h-auto lg:max-w-[390px] justify-center w-full lg:w-2/5">
                    <iframe
                        src="https://lottie.host/embed/3db3a2a3-3e9f-44d1-ad11-4e2fb75630d2/pzFtIPg06I.json"></iframe>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row align-bottom w-full justify-between text-left mb-[160px] px-4 lg:px-0">

                <p className="w-full text-left text-base justify-self-start lg:text-2xl max-w-[700px] md:w-1/2 lg:w-3/5">
                    Remote, hybrid, or on-site, our pre-vetted developers can drive your fintech startup forward.
                </p>

                <ButtonComponent
                    isDisabled={false}
                    classNames="flex mt-auto lg:w-[330px]"
                    isLocal={false}
                    isLink={true}
                    type={ButtonType.PRIMARY}
                    text="Start Now"
                    link="https://bj0gsq7q6qg.typeform.com/to/Mfm2S8kd"
                />
            </div>

            {/* Section: What you Get */}
            <div className="flex card-holder-pinner w-full mb-[300px] px-4 lg:px-0">
                <div className="flex flex-col lg:flex-row justify-between w-full"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 flex-col container-title mb-9 lg:mb-0">What you get:</div>
                    <div className="flex flex-col w-full  lg:w-1/2 items-end">

                        <CardItem
                            title="01 /Pre-vetted talent"
                            indexKey={0}
                            data-animation-item="0px"
                            description="Only developers with proven skills, ready to join your team."
                            classNames="box-1 bg-[#BCAFFF]"/>

                        <CardItem
                            indexKey={1}
                            title="02 /AI-Driven Matches"
                            data-animation-item="70px"
                            description="AI-powered matching for speed and precision in hiring."
                            classNames="box-2 bg-[#EBE7FF]"/>

                        <CardItem
                            indexKey={2}
                            title="03 /Flexible Pricing"
                            data-animation-item="140px"
                            description="Pay only for what you need, with adaptable pricing models."
                            classNames="box-3 bg-[#F3F2FF]"/>
                    </div>
                </div>
            </div>

            <div className="container2"></div>

            {/* Section: Our Platform */}
            <div className="flex card-holder-pinner w-full">
                <div className="flex flex-col lg:flex-row justify-between w-full px-4 lg:px-0"
                     data-animation-item="list">
                    <div className="flex text-[40px] lg:text-[80px] w-full lg:w-1/2 flex-col container-title2 bg-white px-2 lg:px-0 py-10 lg:py-0" style={{maxHeight: '300px'}}>Our platform connects you with:</div>
                    <div className="flex flex-col w-full lg:w-1/2 items-end">

                        <CardItem2
                            title="01 /Frontend Developers"
                            indexKey={0}
                            data-animation-item="0px"
                            description="#VueJS  #ReactJS   #PHP  #..."
                            classNames="box-1 bg-[#BCAFFF]"/>

                        <CardItem2
                            indexKey={1}
                            title="02 /Backend Developers"
                            data-animation-item="70px"
                            description="#C  #Python  #PHP  #Ruby #..."
                            classNames="box-2 bg-[#D8D3FF]"/>

                        <CardItem2
                            indexKey={2}
                            title="03 /FullStack Developers"
                            data-animation-item="140px"
                            description="#ReactJS  #C#  #Python  #PHP  #Ruby  #VueJS  #ReactJS   #PHP  #..."
                            classNames="box-3 bg-[#D8D3FF]"/>

                        <CardItem2
                            indexKey={3}
                            title="04 /QA"
                            data-animation-item="210px"
                            description="#Manual   #Automated &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                            classNames="box-4 bg-[#EBE7FF]"/>

                        <CardItem2
                            indexKey={4}
                            title="05 /DevOps"
                            data-animation-item="280px"
                            description="#Azure  #Azure devops  #Kafka  #Elasticsearch  #Rabbit MQ  #Databases mongo  #Mysql  #Postgresql  #Jenkins  #SSL  #GitlabCI  #Apache  #Nginx  #Prometheus  #Grafana  #Encryption  #System security  #AWS"
                            classNames="bg-[#F3F2FF] box-5"/>
                    </div>
                </div>
            </div>

            <div className="container3 mb-[200px]"></div>

            {/* Section: Transform */}
            <div className="flex flex-col lg:flex-row lg:h-[60vh] bg-white py-40 lg:py-0 px-4 lg:px-0 justify-between items-center w-full">

                <div className="w-full lg:w-3/4 text-left text-[40px] md:text-[80px] lg:text-[80px] mb-40 lg:mb-0">Transform your hiring process and accelerate <span className="text-violet-700 inline">growth with Devsupply.</span></div>

                <ButtonComponent
                    isDisabled={false}
                    classNames="flex lg:w-[330px]"
                    isLocal={false}
                    isLink={true}
                    type={ButtonType.PRIMARY}
                    text="Start Now"
                    link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
                />
            </div>
            {/*<PlugAndPlay />*/}
            {/*<TechRecruitment />*/}
            {/*<SoftwareDevelopment />*/}
        </div>
    );
};

