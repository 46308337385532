import React, {useEffect, useState} from 'react';
import useContentful from "../../API/Hooks/hooks";
import {useParams} from "react-router-dom";
import ShareOnSocial from "react-share-on-social";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

// Create a bespoke renderOptions object to target BLOCKS.EMBEDDED_ENTRY (linked block entries e.g. code blocks)
// INLINES.EMBEDDED_ENTRY (linked inline entries e.g. a reference to another blog post)
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)

type Blog = { slug: string; url: string; content: any; title: string; contentText: string; image: any };

export default function BlogItem() {
    const [post, setPost] = useState<Blog>();
    const [postImage, setPostImage] = useState()
    const [titles, setTitles] = useState();

    const [parts, setParts] = useState<string[]>([]);
    const {getEntryFromContentful} = useContentful();
    const {slug} = useParams();

    const renderOptions = {
        renderNode: {
            [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
                // target the contentType of the EMBEDDED_ENTRY to display as you need
                if (node.data.target.sys.contentType.sys.id === "blogPost") {
                    return (
                        <a href={`/blog/${node.data.target.fields.slug}`}>            {node.data.target.fields.title}
                        </a>
                    );
                }
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
                // target the contentType of the EMBEDDED_ENTRY to display as you need
                if (node.data.target.sys.contentType.sys.id === "codeBlock") {
                    return (
                        <pre>
            <code>{node.data.target.fields.code}</code>
          </pre>
                    );
                }

                if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
                    return (
                        <iframe
                            src={node.data.target.fields.embedUrl}
                            height="100%"
                            width="100%"
                            frameBorder="0"
                            scrolling="no"
                            title={node.data.target.fields.title}
                            allowFullScreen={true}
                        />
                    );
                }
            },

            [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
                // render the EMBEDDED_ASSET as you need
                return (
                    <img
                        src={`https://${node.data.target.fields.file.url}`}
                        height={node.data.target.fields.file.details.image.height}
                        width={node.data.target.fields.file.details.image.width}
                        alt={node.data.target.fields.description}
                    />
                );
            },
        },
    };

    useEffect(() => {
        getEntryFromContentful("blogPost").then((response) => {
            const items = response!.map((item) => item?.fields);
            const _post = items
                .find((post) => post.slug === slug);
            setPost(_post as any);
            setPostImage(post?.image?.fields?.file?.url as any);
        });
    }, []);

    return (
        <div className="BlogItem flex flex-col items-center max-w-[670px] p-6 lg:p-0">
            <h1 className="font-medium text-[#131314] text-[40px] mb-[48px]">{post?.title}</h1>

            {documentToReactComponents(post?.content, renderOptions)}

            <div className="diviader h-0 border-b border-[#6A2BE9] w-full mt-[40px] mb-[24px]"/>

            <div className="social-share items-center flex justify-between w-full">

                <p className="flex self-center mb-0"
                   style={{marginBottom: '0 !important'}}>Share this article</p>

                <div className="social-holder flex items-center">
                    <ShareOnSocial
                        className="flex self-center"
                        textToShare="I’ve read this on Devsupply"
                        link={window.location.href}
                        linkTitle={post?.title}
                        linkMetaDesc={post?.contentText[2]}
                        noReferer
                    >
                        <p className="cursor-pointer underline hover:no-underline transition-all mr-[16px] mb-0">Copy Link</p>
                    </ShareOnSocial>

                    {/*  Facebook   */}
                    <a href="https://www.linkedin.com/company/devsupply-netherlands/?viewAsMember=true"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="hover:scale-125 transition mr-4">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="22" cy="22" r="21.5" fill="white" stroke="#6A2BE9"/>
                            <path
                                d="M32 22C32 16.48 27.52 12 22 12C16.48 12 12 16.48 12 22C12 26.84 15.44 30.87 20 31.8V25H18V22H20V19.5C20 17.57 21.57 16 23.5 16H26V19H24C23.45 19 23 19.45 23 20V22H26V25H23V31.95C28.05 31.45 32 27.19 32 22Z"
                                fill="#6A2BE9"/>
                        </svg>
                    </a>

                    {/* Instagram */}
                    <a href="https://www.instagram.com/devsupply.nl?igsh=bGVqcmdxMHgzczZl"
                       target="_blank"
                       rel="noopener noreferrer"
                       className="hover:scale-125 transition">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <circle cx="22" cy="22" r="21.5" fill="white" stroke="#6A2BE9"/>
                            <path
                                d="M22 18.75C21.138 18.75 20.3114 19.0924 19.7019 19.7019C19.0924 20.3114 18.75 21.138 18.75 22C18.75 22.862 19.0924 23.6886 19.7019 24.2981C20.3114 24.9076 21.138 25.25 22 25.25C22.862 25.25 23.6886 24.9076 24.2981 24.2981C24.9076 23.6886 25.25 22.862 25.25 22C25.25 21.138 24.9076 20.3114 24.2981 19.7019C23.6886 19.0924 22.862 18.75 22 18.75Z"
                                fill="#6A2BE9"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M16.7698 13.0819C20.2458 12.6966 23.7538 12.6966 27.2298 13.0819C29.1288 13.2939 30.6598 14.7889 30.8828 16.6949C31.295 20.2196 31.295 23.7803 30.8828 27.3049C30.6598 29.2109 29.1288 30.7059 27.2308 30.9189C23.7545 31.3043 20.2462 31.3043 16.7698 30.9189C14.8708 30.7059 13.3398 29.2109 13.1168 27.3059C12.7045 23.781 12.7045 20.2199 13.1168 16.6949C13.3398 14.7889 14.8708 13.2939 16.7698 13.0819ZM26.9998 15.9999C26.7346 15.9999 26.4802 16.1053 26.2927 16.2928C26.1052 16.4804 25.9998 16.7347 25.9998 16.9999C25.9998 17.2652 26.1052 17.5195 26.2927 17.707C26.4802 17.8946 26.7346 17.9999 26.9998 17.9999C27.265 17.9999 27.5194 17.8946 27.7069 17.707C27.8945 17.5195 27.9998 17.2652 27.9998 16.9999C27.9998 16.7347 27.8945 16.4804 27.7069 16.2928C27.5194 16.1053 27.265 15.9999 26.9998 15.9999ZM17.2498 21.9999C17.2498 20.7402 17.7503 19.532 18.6411 18.6412C19.5319 17.7504 20.74 17.2499 21.9998 17.2499C23.2596 17.2499 24.4678 17.7504 25.3586 18.6412C26.2494 19.532 26.7498 20.7402 26.7498 21.9999C26.7498 23.2597 26.2494 24.4679 25.3586 25.3587C24.4678 26.2495 23.2596 26.7499 21.9998 26.7499C20.74 26.7499 19.5319 26.2495 18.6411 25.3587C17.7503 24.4679 17.2498 23.2597 17.2498 21.9999Z"
                                  fill="#6A2BE9"/>
                        </svg>
                    </a>

                </div>
            </div>

        </div>
    );
}
