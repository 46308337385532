import React, {useEffect, useState} from "react";
import {DevelopersList} from "../Widgets/aboutUs/DevelopersList";
import {NeedADeveloper} from "../Components/NeedADeveloper";
import {BannerAndTitle} from "../Widgets/aboutUs/BannerAndTitle";

interface AboutUsPageProps {
}

export const AboutUsPage: React.FC<AboutUsPageProps> = () => {
    const [needDev, setNeedDev] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <article className="flex flex-col px-[16px] w-full h-auto gap-6 max-w-[1000px] self-start">
            {/*<BannerAndTitle />*/}
            {/*<DevelopersList />*/}
            {/*{needDev && <NeedADeveloper onClick={() => setNeedDev(false)} />}*/}

            <section className="mb-[60px]">
                <h1 className="w-full text-left text-[40px] md:text-[80px] lg:text-[80px] min:w-3/5 mb-[30px]">
                    Our professional team <br/> will help you find <br/>
                    <span className="text-[#6A2BE9]"> pre-vetted tech talent.</span>
                </h1>

                <p className="text-[24px]">
                    <span className="text-[#6A2BE9]">At DevSupply, we’re not just another recruitment agency;</span> we
                    are
                    your strategic partner in the evolving landscape of fintech talent acquisition. Located in Amsterdam
                    and
                    The Hague, our dedicated team brings together extensive experience in fintech and recruitment,
                    ensuring
                    that we are equipped with the knowledge and insights necessary to connect you with the right talent.
                </p>
            </section>

            <section className="mb-[60px]">
                <h3 className="text-[40px] mb-[24px]">
                    Our Unique Expertise
                </h3>

                <p className="text-[24px]">
                    What sets us apart is our deep-rooted understanding of the fintech sector. Our entire team possesses
                    backgrounds in fintech and recruitment, which means we are not just filling positions; we are
                    strategically aligning talent with your organizational goals. Notably, even our UI/UX designer holds
                    a bachelor’s degree in finance and banking, reflecting our commitment to comprehensively
                    understanding the industry we serve.
                </p>
            </section>


            <section className="mb-[60px]">
                <h3 className="text-[40px] mb-[24px]">
                    Quality-Centric Approach
                </h3>

                <p className="text-[24px]">
                    At DevSupply, quality is at the heart of our recruitment process. We utilize our proprietary
                    AI-powered assessment tool, DevScout, to meticulously vet candidates. This ensures that they not
                    only possess the necessary technical skills but also fit seamlessly into your company culture.
                    Whether your needs lie in blockchain development, digital banking, or software engineering, we
                    provide access to pre-vetted tech talent ready to contribute meaningfully from day one.
                </p>
            </section>


            <section className="mb-[60px]">
                <h3 className="text-[40px] mb-[24px]">
                    Commitment to Your Success
                </h3>

                <p className="text-[24px]">
                    We understand that hiring is a critical component of your business strategy. Our mission is to help
                    you scale smarter and faster while maintaining confidence in your hiring decisions. Let us transform
                    your recruitment process so you can focus on what you do best: building the future of fintech.
                </p>
            </section>

            <section className="mb-[60px]">
                <p className="text-[#6A2BE9] text-[24px]">At DevSupply, we are proud to combine our fintech expertise
                    with a passion for recruitment, ensuring that you have access to top-tier talent that aligns with
                    your vision and values.</p>
            </section>

        </article>
    );
};
