import React from 'react';
import {CardDetails} from "../../../Pages/StartupsPage";
import ButtonComponent, {ButtonType} from "../../../UI/Buttons/ButtonComponent";
import { ReactFlow } from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import "./_Features.scss"

export type FeaturesProps = {}

export type TimelineProps = {
    stepIndex?: number;
    title: (string | JSX. Element)[];
    text: string;
}

export class TimelineItem extends React.Component<TimelineProps> {
    render() {
        return (
            <div className="flex">
                <div className="flex relative mr-[40px]">
                    <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="112" height="112" rx="56" fill="#6A2BE9"/>
                        <path
                            d="M46.82 59.1C39.3 59.1 34.86 53.74 34.86 44.62C34.86 35.46 39.3 30.1 46.82 30.1C54.38 30.1 58.82 35.46 58.82 44.62C58.82 53.74 54.38 59.1 46.82 59.1ZM41.5 44.62C41.5 50.82 43.42 54.1 46.82 54.1C50.26 54.1 52.18 50.82 52.18 44.62C52.18 38.38 50.26 35.1 46.82 35.1C43.42 35.1 41.5 38.38 41.5 44.62ZM67.3397 39.42C66.2997 40.42 64.4197 40.94 62.4997 40.94H61.3797V35.14H62.1397C65.4997 35.02 67.4197 33.74 68.5397 30.7H73.7797V58.5H67.3397V39.42Z"
                            fill="white"/>
                        <path
                            d="M43.0678 87.806C39.8098 87.806 38.1538 86.042 37.9378 83.702L39.6658 83.45C39.8998 85.016 40.8718 86.402 43.1218 86.402C44.5078 86.402 45.9658 85.826 45.9658 84.206C45.9658 82.694 45.0298 82.208 43.5358 81.902L41.6818 81.524C39.8638 81.146 38.4778 80.192 38.4778 77.996C38.4778 75.854 40.2058 74.414 42.9238 74.414C45.4618 74.414 47.4058 75.548 47.6578 78.05L45.9478 78.41C45.7318 76.7 44.7778 75.764 42.8878 75.764C41.3218 75.764 40.2418 76.538 40.2418 77.834C40.2418 79.076 40.9078 79.724 42.3298 80.03L44.2018 80.426C46.1818 80.84 47.7298 81.722 47.7298 84.044C47.7298 86.024 46.2538 87.806 43.0678 87.806ZM48.7202 79.76V78.5H50.3942V76.142L51.9242 75.494V78.5H54.1742V79.76H51.9242V84.746C51.9242 85.772 52.3202 86.222 53.2382 86.222C53.6342 86.222 53.8682 86.168 54.1742 86.096V87.5C53.7782 87.608 53.4182 87.662 52.8422 87.662C50.8982 87.662 50.3942 86.474 50.3942 84.8V79.76H48.7202ZM59.622 87.716C57.048 87.716 55.518 86.078 55.518 83.108C55.518 80.102 57.03 78.284 59.604 78.284C62.034 78.284 63.438 79.67 63.438 82.37V83.45H57.048C57.066 85.214 58.128 86.366 59.73 86.366C60.882 86.366 61.728 85.754 62.124 84.656L63.384 85.214C62.754 86.852 61.44 87.716 59.622 87.716ZM57.048 82.208H61.89V82.01C61.89 80.354 61.044 79.49 59.568 79.49C57.984 79.49 57.084 80.516 57.048 82.208ZM65.9608 91.01V78.5H67.4008V80.174C67.9768 78.932 69.0208 78.284 70.3348 78.284C72.5128 78.284 73.9708 80.156 73.9708 83C73.9708 85.88 72.4228 87.716 70.2448 87.716C68.8588 87.716 67.8868 86.96 67.4908 86.078V91.01H65.9608ZM67.4548 83.198C67.4548 85.142 68.3908 86.366 69.8848 86.366C71.4148 86.366 72.3508 85.106 72.3508 83C72.3508 80.876 71.4508 79.634 69.9388 79.634C68.4268 79.634 67.4548 80.822 67.4548 82.766V83.198Z"
                            fill="white"/>
                    </svg>
                    <div className="absolute">{this.props.stepIndex}</div>
                </div>

                <div className="flex flex-col">
                    <div className="flex ">{this.props.title}</div>
                    <div className="flex ">{this.props.text}</div>
                </div>
            </div>
        );
    }
}

export default function Features({}: FeaturesProps) {


    return (
        <div className="Features flex flex-col lg:flex-row w-full mb-[150px] lg:h-[80vh] px-[16px] items-center">

            <div className="hidden lg:flex flex-col items-center w-full px-11 lg:w-1/2 mb-[40px] lg:mb-0">

                <img src="/Frame 417.png"
                     alt="Frame 417" />

                <img src="/Frame 418.png"
                     alt="Frame 418" />

                <img src="/Frame 419.png"
                     alt="Frame 419" />

            </div>

            <div className="flex w-full lg:hidden mb-20 justify-center">
                <img src="/Mobile.png" alt="Mobile"/>
            </div>

            <div className="flex flex-col w-full lg:w-1/2 mx-auto items-center">

                <div className="flex timeline-item mb-[40px]">
                    <div className="flex flex-col relative w-[52px] min-w-[52px] h-[52px] lg:max-w-[112px] lg:h-[112px] justify-center items-center mr-4 lg:mr-[40px] timeline-circle lg:w-1/5">
                        <div className="flex text-[16px] lg:text-[40px] font-bold timeline-circle__number">01</div>
                        <div className="flex text-[13px] lg:text-[18px] timeline-circle__step">step</div>
                    </div>

                    <div className="flex flex-col lg:w-4/5">
                        <div className="flex text-[24px] lg:text-[32px] font-bold mb-4">Developers apply to <span
                            className='text-purple-700 inline-flex'>&nbsp;Devsupply</span></div>
                        <div className="flex text-[16px] lg:text-[18px] text-[#131314]">Developers go through an instant skills
                            assessment via a streamlined application.
                        </div>
                    </div>
                </div>

                <div className="flex timeline-item mb-[40px]">
                    <div className="flex flex-col relative w-[52px] min-w-[52px] h-[52px] lg:max-w-[112px] lg:h-[112px] justify-center items-center mr-4 lg:mr-[40px] timeline-circle lg:w-1/5">
                        <div className="flex text-[16] lg:text-[40px] font-bold timeline-circle__number">02</div>
                        <div className="flex text-[13px] lg:text-[18px] timeline-circle__step">step</div>
                    </div>

                    <div className="flex flex-col lg:w-4/5">
                        <div className="flex text-[24px] lg:text-[32px] font-bold mb-4">AI-Powered Vetting
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="inline-flex"
                                 width="36"
                                 height="36"
                                 viewBox="0 0 36 36"
                                 fill="none">
                                <g clip-path="url(#clip0_2028_1364)">
                                    <path d="M25.8 17.9998H27.5L26.7 15.7998L25.8 17.9998Z" fill="#EA3E2B"/>
                                    <path
                                        d="M10.4 17.5H8.5V19H10.1C10.5 19.1 10.9 18.8 11 18.4C11.1 18 10.8 17.6 10.4 17.5Z"
                                        fill="#EA3E2B"/>
                                    <path
                                        d="M10.7 15.7996C10.7 15.3996 10.4 15.0996 9.9 15.0996H8.5V16.3996H9.9C10.4 16.4996 10.7 16.1996 10.7 15.7996Z"
                                        fill="#EA3E2B"/>
                                    <path
                                        d="M33.1 9H3.10001C2.50001 9 2.10001 9.4 2.10001 10V24C2.10001 24.6 2.50001 25 3.10001 25H7.10001V29C7.10001 29.4 7.30001 29.8 7.70001 29.9C7.80001 30 7.90001 30 8.10001 30C8.40001 30 8.60001 29.9 8.80001 29.7L13.5 25H33.1C33.7 25 34.1 24.6 34.1 24V10C34.1 9.4 33.6 9 33.1 9ZM10.4 20.1H7.20001V14.1H10.2C11.1 14 11.9 14.6 12 15.5V15.6C12 16.2 11.7 16.7 11.2 16.9C11.8 17.1 12.3 17.7 12.3 18.4C12.2 19.4 11.4 20.1 10.4 20.1ZM17.9 15.2H14.6V16.4H17.6V17.6H14.6V18.9H17.9V20.1H13.3V14.1H17.9V15.2ZM21.7 20.1H20.4V15.3H18.5V14.1H23.5V15.3H21.7V20.1ZM28.4 20.1L28 19.1H25.3L24.9 20.1H23.5L25.9 14.1H27.3L29.8 20.1H28.4Z"
                                        fill="#EA3E2B"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2028_1364">
                                        <rect width="36" height="36" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="flex text-[16px] lg:text-[18px] text-[#131314]">Our fun and effective AI-driven gamified
                            process selects the top 1% of candidates based on skills
                        </div>
                    </div>
                </div>

                <div className="flex timeline-item mb-[40px]">
                    <div className="flex flex-col relative w-[52px] min-w-[52px] h-[52px] lg:max-w-[112px] lg:h-[112px] justify-center items-center mr-4 lg:mr-[40px] timeline-circle lg:w-1/5">
                        <div className="flex text-[16px] lg:text-[40px] font-bold timeline-circle__number">03</div>
                        <div className="flex text-[13px] lg:text-[18px] timeline-circle__step">step</div>
                    </div>

                    <div className="flex flex-col lg:w-4/5">
                        <div className="flex text-[24px] lg:text-[32px] font-bold mb-4">Technical Interview</div>
                        <div className="flex text-[16px] lg:text-[18px] text-[#131314]">A thorough technical interview with our expert
                            team to validate the developer's abilities.
                        </div>
                    </div>
                </div>

                <div className="flex timeline-item mb-[40px]">
                    <div className="flex flex-col relative w-[52px] min-w-[52px] h-[52px] lg:max-w-[112px] lg:h-[112px] justify-center items-center mr-4 lg:mr-[40px] timeline-circle lg:w-1/5">
                        <div className="flex text-[16px] lg:text-[40px] font-bold timeline-circle__number">04</div>
                        <div className="flex text-[13px] lg:text-[18px] timeline-circle__step">step</div>
                    </div>

                    <div className="flex flex-col lg:w-4/5">
                        <div className="flex text-[24px] lg:text-[32px] font-bold mb-4">Cultural Fit Interview</div>
                        <div className="flex text-[16px] lg:text-[18px] text-[#131314]">We assess if the candidate is aligned with your
                            company’s culture, ensuring a good team fit.
                        </div>
                    </div>
                </div>

                <div className="flex timeline-item mb-[40px]">

                    <div className="flex flex-col relative w-[52px] min-w-[52px] h-[52px] lg:max-w-[112px] lg:h-[112px] justify-center items-center mr-4 lg:mr-[40px] timeline-circle lg:w-1/5">
                        <div className="flex text-[16px] lg:text-[40px] font-bold timeline-circle__number">05</div>
                        <div className="flex text-[13px] lg:text-[18px] timeline-circle__step">step</div>
                    </div>

                    <div className="flex flex-col lg:w-4/5">
                        <div className="flex text-[24px] lg:text-[32px] font-bold mb-4">Become a DevSupply Member</div>
                        <div className="flex text-[16px] lg:text-[18px] text-[#131314]">Once vetted, the developer is fully ready to be introduced to you and join your fintech startup.</div>
                    </div>
                </div>


                <ButtonComponent
                    isDisabled={false}
                    isLocal={false}
                    isLink={true}
                    classNames="flex ml-auto py-[16px] lg:w-[330px] timeline-end timeline-control mt-[40px] lg:mt-0"
                    type={ButtonType.PRIMARY}
                    text="Schedule a call"
                    link="https://bj0gsq7q6qg.typeform.com/to/M5Gi2dBQ"
                />
            </div>

        </div>
    );
}
