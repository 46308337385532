import {Footer} from "../Widgets/Footer";
import {Banner} from "../Widgets/homepage/Banner";
import {NeedADeveloper} from "../Components/NeedADeveloper";
import {WhyDevSupply} from "../Widgets/homepage/WhyDevSupply";
import {GrowWithUs} from "../Widgets/homepage/GrowWithUs";
import React, {useEffect, useState} from "react";
import Partnership from "../Widgets/homepage/Partnership/Partnership";
import Features from "../Widgets/homepage/Features/Features";

interface HomePageProps {
}

export const HomePage: React.FC<HomePageProps> = () => {
    const [needDev, setNeedDev] = useState(true);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <div className="flex flex-col w-full h-full justify-center items-center gap-6">
            <Banner/>

            <Features/>

            <WhyDevSupply/>

            {/*<Partnership/>*/}

            <GrowWithUs/>

            {/*<ConnectingYou />*/}
            {/*<WhoWeAre />*/}
            {/*<RollingImages />*/}
            {/*<Advantages />*/}
            {/*{needDev && <NeedADeveloper onClick={() => setNeedDev(false)} />}*/}

        </div>
    );
};
